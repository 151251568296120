import React, { useEffect, useState } from 'react';
import { View, Text, Switch, ActivityIndicator } from "react-native";
import { ConstructionSite, Contract, Doc } from "../../../../models";
import { getOrderByContractId } from "../../../../api/DocAPI";
import OrderLines from "./OrderLines";
import { t } from 'i18n-js';
import getTheme from "../../../../constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import { Reducer } from "../../../../store/reducers";
import { PriceLevel } from "../../../../store/reducers/settingsReducer";
import { sapDocToDocConvert } from '../../../../shared-utils/ConversionUtils'
import KeyboardHide from "../../../../components/KeyboardHide";
import { alert } from "../../../../hooks/Alert";
import DocPreviewMenu from '../../../../components/DocPreviewMenu';
import { logout } from '../../../../store/action/authAction';
import Button from '../../../../components/Button';
import { sentryCapture } from '../../../../utils/sentryCapture';
import { useNavigation } from '@react-navigation/native';
import { SearchScreens } from '../../../search/SearchScreens';
import OrderPreviewClients from './OrderPreviewClients'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { downloadIndustrialAccounting } from '../../../../utils/measure/util';

interface Props {
  contract: Contract;
  largeView: boolean;
  constructionSite: ConstructionSite;
  setSelectedOverview: () => void;
}

const OrderView = ({contract, largeView, constructionSite, setSelectedOverview}: Props) => {

  const navigation = useNavigation()
  const dispatch = useDispatch();

  const workSpace = useSelector((state: Reducer) => state.auth.workSpace)
  const token = useSelector((state: Reducer) => state.auth.token)

  const [order, setOrder] = useState<Doc | null>();
  const [showAsMonetaryValue, setShowAsMonetaryValue] = useState<boolean>(false);
  const [orderLoading, setOrderLoading] = useState<boolean>(true);

  const [
    loadingIndustrialAccounting,
    setLoadingIndustrialAccounting,
  ] = useState<boolean>(false)

  const showPriceLevel = useSelector((state: Reducer) => state.settings.showPriceLevel);
  const sales = useSelector((state: Reducer) => state.user.permissions.sales);
  const catalogs = useSelector((state: Reducer) => state.general.catalogs);

  const pull = () => {
    getOrderByContractId(contract.ID).then(sapDoc => {
      const doc = sapDocToDocConvert(sapDoc, catalogs);
      setOrder(doc);
    }).catch((e) => {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR_UPDATING", "ORDER_ERROR_DOWNLOADING");
      }
    }).finally(() => setOrderLoading(false));
  };

  useEffect(() => {
    pull();
  }, [contract.ID]);

  const theme = getTheme();

  if (orderLoading) {
    return <ActivityIndicator />;
  }

  if (!order) {
    return <View/>;
  }

  // @ts-ignore
  const _editOrder = () => navigation.navigate(SearchScreens.EDIT_ORDER, {
    constructionSite,
    contract,
    order,
  })

  const _industrialAccounting = () => {
    downloadIndustrialAccounting(
      workSpace?.secondaryUrl || '',
      workSpace?.name || '',
      token,
      order.ContractID,
      `${constructionSite.Code}-${constructionSite.Name}`,
      setLoadingIndustrialAccounting,
    )
  }

  return (
    <View style={{flexDirection: 'column', flex: 1}}>
      <KeyboardHide>
        <View style={{backgroundColor: 'white', margin: 5, borderRadius: 5, padding: 8, flexDirection: "row", alignItems: 'center'}}>
          <View style={{flex: 1, flexDirection: 'row'}}>
            <OrderPreviewClients doc={order} />
          </View>
          {sales && (
            <Button
              style={{marginRight: 6, backgroundColor: theme.blue}}
              onPress={_industrialAccounting}
              disabled={loadingIndustrialAccounting}
            >
              {loadingIndustrialAccounting ? (
                <ActivityIndicator color='white' />
              ) : (
                <MaterialCommunityIcons
                  name='download'
                  size={20}
                  color='white'
                />
              )}
            </Button>
          )}
          {sales && (<Button style={{marginRight: 6, backgroundColor: theme.blue}} onPress={_editOrder} titleT="EDIT"/>)}
          {sales && (
            <DocPreviewMenu
              doc={order}
              contract={contract}
              constructionSite={constructionSite}
              setSelectedOverview={setSelectedOverview}
            />
          )}
        </View>

        {showPriceLevel >= PriceLevel.CLIENT && (
          <View
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "row",
              margin: 5,
              paddingRight: 8
            }}>
            <Text>{t('SHOW_AS_MONETARY_VALUE')}</Text>
            <Switch style={{marginLeft: 8}}
                    onValueChange={setShowAsMonetaryValue}
                    value={showAsMonetaryValue}/>
          </View>
        )}
      </KeyboardHide>
      <View
        style={{
          backgroundColor: "white",
          margin: 5,
          borderRadius: 5,
          padding: 8,
          flex: 1,
        }}>
        <OrderLines order={order}
                    largeView={largeView}
                    showAsMonetaryValue={showAsMonetaryValue}
                    showPriceLevel={showPriceLevel}
        />
      </View>
    </View>
  )
};

export default OrderView;