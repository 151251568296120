import React, { useEffect, useMemo, useState } from 'react'
import getTheme from '../../../constants/Colors'
import { ActivityIndicator, FlatList, Text, View } from 'react-native'
import { t } from 'i18n-js'
import { getBonusesByYear, insertOrUpdateBonuses } from '../../../api/BonusAPI'
import { useSelector } from 'react-redux'
import { Reducer } from '../../../store/reducers'
import { alert, alertConfirm } from '../../../hooks/Alert'
import { sentryCapture } from '../../../utils/sentryCapture'
import { Bonus, Resource } from '../../../models'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import Button from '../../../components/Button'
import ConfigureBonus from './ConfigureBonus'
import ChooseResourceModal from './ChooseResourceModal'

interface ConfigureBonusesProps {
  route: {
    params?: {
      year?: number,
    }
  }
}

const ConfigureBonuses = ({ route }: ConfigureBonusesProps) => {

  const theme = getTheme()
  const navigation = useNavigation()

  const token = useSelector((state: Reducer) => state.auth.token)
  const workSpace = useSelector((state: Reducer) => state.auth.workSpace)

  const resources = useSelector((state: Reducer) => state.general.resources)

  const [showChooseNewModal, setShowChooseNewModal] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)
  const [bonuses, setBonuses] = useState<Bonus[]>([])
  const [changed, setChanged] = useState<boolean>(false)

  const freeResources = useMemo(() => {
    const usedResources = bonuses.map(b => b.resourceId)
    const activeAndOperativeResources = resources
        .filter(r => r.Active && r.ResourceType.Operative)

    return activeAndOperativeResources
        .filter(r => !usedResources.includes(r.ID))
  }, [bonuses, resources])

  const pull = async () => {
    if (!workSpace || !route.params?.year) {
      return
    }

    if (changed) {
      if (!await alertConfirm('WARNING', 'CHANGES_WILL_BE_LOST')) {
        return
      }
    }

    setLoading(true)
    getBonusesByYear(
      workSpace?.secondaryUrl,
      token,
      workSpace?.name,
      route.params?.year,
    ).then(setBonuses).catch(e => {
      alert(t('ERROR'), t('ERROR_FETCHING_BONUSES'))
      sentryCapture(e)
    }).finally(() => setLoading(false))
  }

  useEffect(() => {
    pull()
  }, [])

  const _back = () => navigation.goBack()

  const _save = async () => {
    if (loading && !changed) {
      return
    }

    setLoading(true)
    insertOrUpdateBonuses(
      workSpace?.secondaryUrl || '',
      token,
      workSpace?.name || '',
      bonuses,
    ).then(() => {
      setChanged(false)
    }).catch(e => {
      alert(t('ERROR'), t('ERROR_SAVING_BONUSES'))
      sentryCapture(e)
      console.log(e)
    }).finally(() => setLoading(false))
  }

  const _add = () => setShowChooseNewModal(true)
  const _closeChooseNewModal = () => setShowChooseNewModal(false)
  const _chooseNew = (resource: Resource) => {
    setBonuses([
      ...bonuses,
      {
        year: route.params?.year || 0,
        resourceId: resource.ID,
        salary: 0,
        bonusJune: 0,
        bonusDecember: 0,
        resource,
      }
    ])
    setChanged(true)
  }

  const _copyFromPreviousYear = () => {
    const year = route.params?.year || new Date().getFullYear()
    getBonusesByYear(
      workSpace?.secondaryUrl || '',
      token,
      workSpace?.name || '',
      year - 1,
    ).then(bonuses => {
      setBonuses(bonuses.map(b => ({
        ...b,
        year,
        bonusJune: 0,
        bonusDecember: 0,
      })))
      setChanged(true)
    }).catch(e => {
      alert(t('ERROR'), t('ERROR_FETCHING_BONUSES'))
      sentryCapture(e)
    })
  }

  const _render = ({item, index}: {item: Bonus, index: number}) => {
    const setBonus = (bonus: Bonus) => {
      setChanged(true)
      setBonuses(
        bonuses.map((b, i) => i === index ? bonus : b)
      )
    }
    const remove = () => {
      setBonuses(
        bonuses.filter((_, i) => i !== index)
      )
    }
    return (
      <ConfigureBonus
        index={index}
        item={item}
        remove={remove}
        setBonus={setBonus}
      />
    )
  }

  return (
    <View style={{flex: 1}}>
      {showChooseNewModal && (
        <ChooseResourceModal
          onClose={_closeChooseNewModal}
          onSelect={_chooseNew}
          resources={freeResources}
        />
      )}
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        <MaterialCommunityIcons
          name='close'
          size={34}
          color='black'
          onPress={_back}
          style={{marginLeft: 10}}
        />

        <Text style={{
          fontSize: 24,
          margin: 10,
          flex: 1,
        }}>{t('CONFIGURE_BONUESES')} - {t('YEAR')} {route.params?.year || t('ERROR')}</Text>

        <MaterialCommunityIcons
          name='reload'
          size={24}
          color='black'
          onPress={pull}
          style={{marginRight: 10}}
        />

        {(bonuses.length == 0 && !loading) && (
          <Button
            style={{
              backgroundColor: theme.red,
              marginRight: 10,
            }}
            onPress={_copyFromPreviousYear}
            titleT='COPY_FROM_PREVIOUS_YEAR'
          />
        )}

        {freeResources.length > 0 && (
          <Button
            style={{
              backgroundColor: theme.blue,
              marginRight: 10,
            }}
            onPress={_add}
          >
            <MaterialCommunityIcons
              name='plus'
              size={24}
              color='white'
            />
          </Button>
        )}

        {changed && (
          <Button
            titleT='SAVE'
            style={{
              backgroundColor: theme.mainColor,
              marginRight: 10,
              paddingVertical: 8
            }}
            _textStyle={{fontSize: 18}}
            onPress={_save}
          />
        )}
      </View>

      <View style={{
        flexDirection: 'row',
        alignItems: 'flex-end',
        paddingHorizontal: 25,
      }}>
        <Text style={{flex: 1}}>{t('RESOURCE')}</Text>
        <Text style={{flex: 1, textAlign: 'right', paddingHorizontal: 10}}>
          {t('SALARY')}
        </Text>
        <Text style={{flex: 1, textAlign: 'right', paddingLeft: 10}}>
          {t('BONUS_JUNE')}
        </Text>
        <Text style={{
          flex: 1,
          textAlign: 'right',
          paddingLeft: 20,
          paddingRight: 45,
        }}>
          {t('BONUS_DECEMBER')}
        </Text>
      </View>

      {loading ? (
        <ActivityIndicator color={theme.mainColor} />
      ) : (
        <FlatList
          data={bonuses}
          keyExtractor={item => item.year + ' ' + item.resourceId}
          style={{
            paddingHorizontal: 15,
          }}
          renderItem={_render}
        />
      )}
    </View>
  )
}

export default ConfigureBonuses
