import React from "react";
import { View } from "react-native";
import { Doc } from "../../../../models";
import DocumentPreviewContact from "./DocumentPreviewContact";
import DocumentPreviewClient from "./DocumentPreviewClient";

interface Props {
  doc: Doc;
}

const DocumentPreviewClients = ({doc}: Props) => {

  return (
    <View style={{flex: 1}}>
      <DocumentPreviewClient
        textT='CLIENT'
        client={doc.Client}
        contact={doc.ClientContact}
      />

      {doc.By && (
        <DocumentPreviewClient
          textT='BY'
          client={doc.By}
          contact={doc.ByContact}
        />
      )}

      {doc.InvoiceTo && (
        <DocumentPreviewClient
          textT='INVOICE_TO'
          client={doc.InvoiceTo}
          contact={doc.InvoiceToContact}
        />
      )}
      
      {doc.OfferNamedTo && (
        <DocumentPreviewClient
          textT='OFFER_NAMED_TO'
          client={doc.OfferNamedTo}
          contact={doc.OfferNamedToContact}
        />
      )}

      {doc.DocumentContacts.map(d => <DocumentPreviewContact documentContact={d} key={d.ID} />)}
    </View>
  )
};

export default DocumentPreviewClients