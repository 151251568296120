import React, { useEffect, useState } from "react";
import { WorkSpace } from "../../models";
import { GetWorkspaces } from "../../api/Workspace";
import { View, StyleSheet, Text, ActivityIndicator, Platform, KeyboardAvoidingView } from "react-native";
import { t } from 'i18n-js';
import LoginComponent from "./LoginComponent";
import WorkSpaceInput from "./WorkSpaceInput";
import CheckingWorkSpace from "./CheckingWorkSpace";
import { getInitialURL } from "expo-linking";
import { sentryCapture } from "../../utils/sentryCapture";
import { useSelector } from "react-redux";
import { Reducer } from "../../store/reducers";
import { DeviceType, deviceType } from 'expo-device'

interface Props {

}

const AuthScreen = ({}:Props) => {

  const _workSpace = useSelector((state: Reducer) => state.auth.workSpace)
  const [workSpaces, setWorkSpaces] = useState<WorkSpace[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [checking, setChecking] = useState<boolean>(false);

  const [workSpacePlaceholder, setWorkSpacePlaceholder] = useState<string>();

  const [workSpace, setWorkSpace] = useState<WorkSpace | undefined>(_workSpace);

  const [error, setError] = useState<any>();

  useEffect(() => {
    GetWorkspaces()
      .then(workSpaces => {
        if (Platform.OS == "web") {
          getInitialURL().then(l => {
            if (l) {
              let c = l.lastIndexOf('//');
              let url = l.substring(c + 2);
              if (url.lastIndexOf('/') > 0) {
                url = url.substring(url.lastIndexOf('/') + 1);
                const workSpace = workSpaces.find(w => w.name == url);
                if (workSpace) {
                  setWorkSpace(workSpace);
                }
              }
            }
          });
        }
        setWorkSpaces(workSpaces);
      })
      .catch(e => {
        sentryCapture(e);
        setError(e);
      })
      .finally(() => setLoading(false));
  });

  const closeChecking = () => {
    setWorkSpacePlaceholder(workSpace?.name);
    setWorkSpace(undefined);
    setChecking(false);
    setLoading(false);
  }

  const _setWorkSpace = (workSpace?: WorkSpace) => {
    workSpace && setChecking(true);
    setWorkSpace(workSpace);
  }
  const okChecking = () => setChecking(false);

  if (deviceType == DeviceType.PHONE) {
    return (
      <KeyboardAvoidingView style={{paddingTop: 60}} behavior="padding">
        <View style={{paddingHorizontal: 40}}>
          <Text style={{fontSize: 44, marginTop: 20}}>DRYapp</Text>

          <Text style={{marginTop: 20}}>
            {t("INSERT_WORK_SPACE_TO_SIGN_IN")}
          </Text>
        </View>

        <View style={{padding: 40}}>
          {
            error && <Text>{t("ERROR_STARTING_APP")}</Text>
          }

          {
            loading && <ActivityIndicator />
          }

          {
            (checking && workSpace) && (
              <CheckingWorkSpace
                workSpace={workSpace}
                back={closeChecking}
                ok={okChecking}
              />
            )
          }

          {
            !error && !loading && !checking && (
              workSpace ? (
                <LoginComponent
                  back={closeChecking}
                  workSpace={workSpace}
                />
              ) : (
                <WorkSpaceInput
                  setWorkSpace={_setWorkSpace}
                  workSpaces={workSpaces}
                  workSpace={workSpace}
                  workSpacePlaceholder={workSpacePlaceholder}
                />
              )
            )
          }

          <View style={{justifyContent: 'flex-end', flexDirection: 'row', paddingTop: 40}}>
            <Text>{t("CONTACT_SUPPORT")}{/* TODO CREATE LINK */}</Text>
          </View>
        </View>
      </KeyboardAvoidingView>
    )
  }

  return (
    <KeyboardAvoidingView style={styles.container} behavior="padding">
      <View style={[styles.side, {flex: 3}]}>
        <Text style={{fontSize: 44, marginTop: 20}}>DRYapp</Text>

        <Text style={{marginTop: 20}}>
          {t("INSERT_WORK_SPACE_TO_SIGN_IN")}
        </Text>
      </View>

      <View style={[styles.side, {alignSelf: 'center', flex: 5}]}>
        {
          error && <Text>{t("ERROR_STARTING_APP")}</Text>
        }

        {
          loading && <ActivityIndicator />
        }

        {
          (checking && workSpace) && (
            <CheckingWorkSpace
              workSpace={workSpace}
              back={closeChecking}
              ok={okChecking}
            />
          )
        }

        {
          !error && !loading && !checking && (
            workSpace ? (
              <LoginComponent
                back={closeChecking}
                workSpace={workSpace}
              />
            ) : (
              <WorkSpaceInput
                setWorkSpace={_setWorkSpace}
                workSpaces={workSpaces}
                workSpace={workSpace}
                workSpacePlaceholder={workSpacePlaceholder}
              />
            )
          )
        }

        <View style={{justifyContent: 'flex-end', flexDirection: 'row', paddingTop: 40}}>
          <Text>{t("CONTACT_SUPPORT")}{/* TODO CREATE LINK */}</Text> 
        </View>
      </View>
    </KeyboardAvoidingView>
  )
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    padding: 40,
  },
  side: {
    flexDirection: 'column',
    padding: 40,
  },
});

export default AuthScreen;