export const SET_WORK_SPACE = "SET_WORK_SPACE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const SET_FIRST_START = "SET_FIRST_START";

export const GET_BASIC_DATA_SUCCESS = "GET_BASIC_DATA_SUCCESS";
export const GET_BASIC_DATA_LOADING = "GET_BASIC_DATA_LOADING";

export const SERVER_REACHABLE = "SERVER_REACHABLE";
export const CHECK_NETWORK = 'CHECK_NETWORK'

export const SET_SHOW_PRICE = "SET_SHOW_PRICE";

export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const SET_USER_LOADING = "SET_USER_LOADING";

export const GET_CATALOGS_SUCCESS = "GET_CATALOGS_SUCCESS";
export const CATALOGS_LOADING = "CATALOGS_LOADING";

export const GET_CONSTRUCTION_SITES_SUCCESS = "GET_CONSTRUCTION_SITES_SUCCESS";
export const CONSTRUCTION_SITES_LOADING = "CONSTRUCTION_SITES_LOADING";


export const GET_ALL_CLIENTS_LOADING = "GET_ALL_CLIENTS_LOADING";
export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
export const NEW_CLIENT = "NEW_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";

export const SET_SEARCH_STRING = "SET_SEARCH_STRING";

export const MEASURE_SHOW_SOLD = 'MEASURE_SHOW_SOLD'
export const MEASURE_SHOW_DONE_QUANTITY_PREVIOUS = 'MEASURE_SHOW_DONE_QUANTITY_PREVIOUS_CHANGED'
export const MEASURE_SHOW_APPROVED_QUANTITY_PREVIOUS = 'MEASURE_SHOW_APPROVED_QUANTITY_PREVIOUS_CHANGED'
export const MEASURE_SHOW_BILLABLE_QUANTITY_PREVIOUS = 'MEASURE_SHOW_BILLABLE_QUANTITY_PREVIOUS_CHANGED'
export const MEASURE_SHOW_BILLED_QUANTITY = 'MEASURE_SHOW_BILLED_QUANTITY_CHANGED'

export const SHOW_OFFLINE_MEASURES = 'SHOW_OFFLINE_MEASURES'

export const SHOW_DOCS_OFFLINE = 'SHOW_DOCS_OFFLINE'

export const SHOW_PLANNING_WEEK_DAYS = 'SHOW_PLANNING_WEEK_DAYS'

export const ANALYTIC17_CONFIG_ALL_OPEN = 'ANALYTIC17_CONFIG_ALL_OPEN'
export const ANALYTIC17_CONFIG_PRICE_LIST = 'ANALYTIC17_CONFIG_PRICE_LIST'
export const ANALYTIC17_CONFIG_USE_BOM_ENGINEERING = 'ANALYTIC17_CONFIG_USE_BOM_ENGINEERING'
export const ANALYTIC17_CONFIG_USE_BOM_EXTERNAL_COSTS = 'ANALYTIC17_CONFIG_USE_BOM_EXTERNAL_COSTS'
export const ANALYTIC17_CONFIG_USE_BOM_SPECIAL_MATERIAL = 'ANALYTIC17_CONFIG_USE_BOM_SPECIAL_MATERIAL'
export const ANALYTIC17_CONFIG_USE_HOURS_COST = 'ANALYTIC17_CONFIG_USE_HOURS_COST'
export const ANALYTIC17_CONFIG_SHOW_CONTRACT = 'ANALYTIC17_CONFIG_SHOW_CONTRACT'

export const ANALYTIC22_23_CONFIG_YEAR = 'ANALYTIC22_23_CONFIG_YEAR'
export const ANALYTIC22_23_CONFIG_PREVIOUS_YEARS = 'ANALYTIC22_23_CONFIG_PREVIOUS_YEARS'
export const ANALYTIC22_23_CONFIG_PRICE_LIST = 'ANALYTIC22_23_CONFIG_PRICE_LIST'
export const ANALYTIC22_23_CONFIG_WAREHOUSE_CONSTRUCTION_SITE_CODE = 'ANALYTIC22_23_CONFIG_WAREHOUSE_CONSTRUCTION_SITE_CODE'
