import React, { useMemo, useState } from 'react'
import getTheme from '../../../constants/Colors'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { Reducer } from '../../../store/reducers'
import { setAnalytic22_23ConfigPreviousYears, setAnalytic22_23ConfigPriceList, setAnalytic22_23ConfigWarehouseConstructionSiteCode, setAnalytic22_23ConfigYear } from '../../../store/action/settingsAction'
import AnalyticsScreens from '../AnalyticsScreens'
import Button from '../../../components/Button'
import { ActivityIndicator, Text, TextInput, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { t } from 'i18n-js'
import FlatChoose from '../../../components/FlatChoose'
import { getAsipCostLevels } from '../util'
import { downloadAnalytic22_23 } from './downloadAnalytic22_23'
import DatePicker from '../../../components/DatePicker'

const getInitStartDate = () => {
  const startDate = new Date()
  startDate.setMonth(0)
  startDate.setDate(1)
  return startDate
}

const getInitEndDate = () => {
  const endDate = new Date()
  endDate.setMonth(11)
  endDate.setDate(31)
  return endDate
}

const Analytic22_23Screen = () => {

  const theme = getTheme()
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const token = useSelector((state: Reducer) => state.auth.token)
  const workSpace = useSelector((state: Reducer) => state.auth.workSpace)
  const asipCostLevel = useSelector(
    (state: Reducer) => state.user.ASIPCostLevel
  )

  const year = useSelector((state: Reducer) => state.settings.analytic22_23.year)
  const previousYears = useSelector(
    (state: Reducer) => state.settings.analytic22_23.previousYears
  )
  const priceList = useSelector(
    (state: Reducer) => state.settings.analytic22_23.priceList
  )
  const warehouseConstructionSiteCode = useSelector(
    (state: Reducer) => state.settings.analytic22_23.warehouseConstructionSiteCode
  )

  const [startDate, setStartDate] = useState<Date>(getInitStartDate())
  const [endDate, setEndDate] = useState<Date>(getInitEndDate())
  const [loading, setLoading] = useState<boolean>(false)

  const _startDate = (date?: Date) => setStartDate(date || getInitStartDate())
  const _endDate = (date?: Date) => setEndDate(date || getInitEndDate())

  const asipPriceLevels = useMemo(
    () => getAsipCostLevels(asipCostLevel),
    [asipCostLevel],
  )

  const _year = (year: string) => {
    dispatch(setAnalytic22_23ConfigYear(parseInt(year)))
  }

  const _previousYears = (previousYears: string) => {
    dispatch(setAnalytic22_23ConfigPreviousYears(parseInt(previousYears)))
  }

  const _priceList = (priceList: number) => {
    dispatch(setAnalytic22_23ConfigPriceList(priceList))
  }

  const _warehouseConstructionSiteCode = (warehouseConstructionSiteCode: string) => {
    dispatch(setAnalytic22_23ConfigWarehouseConstructionSiteCode(warehouseConstructionSiteCode))
  }

  const _download = () => {
    if (loading) {
      return
    }

    setLoading(true)
    downloadAnalytic22_23(
      workSpace?.secondaryUrl || '',
      workSpace?.name || '',
      token || '',
      {
        year,
        previousYears,
        priceList,
        warehouseConstructionSiteCode,
        startDate,
        endDate,
      },
      setLoading,
    )
  }

  const _configureBonuses = () => {
    // @ts-ignore
    navigation.navigate(AnalyticsScreens.ConfigureBonuses, {
      year,
    })
  }

  const _back = () => navigation.goBack()

  return (
    <View>
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
      }}>
        <MaterialCommunityIcons
          name='close'
          size={34}
          color='black'
          onPress={_back}
        />
        <Text style={{fontSize: 20, marginLeft: 10}}>
          {t('ANALYTIC')} 22/23 - {t('TECNICIANS_AVERAGES')}
        </Text>
      </View>

      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
      }}>
        <Text style={{fontSize: 16}}>
          {t('START_DATE')}:
        </Text>

        <DatePicker
          value={startDate}
          onChange={_startDate}
        />

        <Text style={{fontSize: 16, marginLeft: 30}}>
          {t('END_DATE')}:
        </Text>

        <DatePicker
          value={endDate}
          onChange={_endDate}
        />
      </View>

      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
      }}>
        <Text style={{fontSize: 16, marginRight: 10}}>
          {t('YEAR')}:
        </Text>
        <TextInput
          value={year.toString()}
          onChangeText={_year}
          style={{
            padding: 5,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: theme.border,
            backgroundColor: 'white',
            width: 100,
          }}
          keyboardType='numeric'
        />
        <Text style={{fontSize: 16, marginHorizontal: 10}}>
          {t('PREVIOUS_YEARS')}:
        </Text>
        <TextInput
          value={previousYears.toString()}
          onChangeText={_previousYears}
          style={{
            padding: 5,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: theme.border,
            backgroundColor: 'white',
            width: 100,
          }}
          keyboardType='numeric'
        />
        <Text style={{fontSize: 16, marginHorizontal: 10}}>
          {t('WAREHOUSE_CONSTRUCTION_SITE_CODE')}:
        </Text>
        <TextInput
          value={warehouseConstructionSiteCode.toString()}
          onChangeText={_warehouseConstructionSiteCode}
          style={{
            padding: 5,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: theme.border,
            backgroundColor: 'white',
            width: 100,
          }}
        />
      </View>

      <View style={{
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        <Text style={{
          fontSize: 16,
          paddingRight: 20,
        }}>
          {t('PRICE_LIST')}:
        </Text>

        <View style={{flex: 4}}>
          <FlatChoose
            itemToKey={i => i.toString()}
            options={asipPriceLevels}
            itemToString={i => i.toString()}
            onChange={_priceList}
            size='medium'
            value={priceList}
          />
        </View>
        <View style={{flex: 2, paddingLeft: 40}} />
      </View>

      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}>
        <Button
          onPress={_configureBonuses}
          titleT='CONFIGURE_BONUSES'
          style={{backgroundColor: theme.blue, paddingVertical: 10}}
        />
        <Button
          style={{backgroundColor: theme.blue}}
          onPress={_download}
          disabled={loading}
        >
          {loading ? (
            <ActivityIndicator color='white' />
          ) : (
            <MaterialCommunityIcons
              name='file-table'
              size={24}
              color='white'
            />
          )}
        </Button>
      </View>
    </View>
  )
}

export default Analytic22_23Screen
