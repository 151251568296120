import { t } from 'i18n-js'
import React from 'react'
import { Pressable, ScrollView, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { Reducer } from '../../store/reducers'
import getTheme from '../../constants/Colors'
import { useNavigation } from '@react-navigation/native'
import AnalyticsScreens from './AnalyticsScreens'

const AnalyticsScreen = () => {

  const theme = getTheme()
  const navigation = useNavigation()

  const user = useSelector((state: Reducer) => state.user)

  if (!user.analytics?.enabled) {
    return (
      <View style={{padding: 5, flex: 1}}>
        <Text style={{
          fontSize: 34,
        }}>
          {t('ANALYTICS')}
        </Text>
        <View style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Text>{t('NO_PERMISSIONS')}</Text>
        </View>
      </View>
    )
  }

  const _analytic17 = () => {
    // @ts-ignore
    navigation.navigate(AnalyticsScreens.Analytic17)
  }

  const _analytic22_23 = () => {
    // @ts-ignore
    navigation.navigate(AnalyticsScreens.Analytic22_23)
  }

  return (
    <View style={{
      paddingTop: 5,
      paddingHorizontal: 5,
      flex: 1,
    }}>
      <Text style={{
        fontSize: 34,
      }}>
        {t('ANALYTICS')}
      </Text>
      <ScrollView style={{
        flex: 1,
        marginTop: 5,
        paddingHorizontal: 10,
        backgroundColor: 'white',
        borderRadius: 5,
      }}>
        {user.analytics.stat17 && (
          <Pressable
            onPress={_analytic17}
            style={{
              paddingVertical: 15,
              paddingHorizontal: 5,
              borderBottomColor: theme.border,
              borderBottomWidth: 1,
              flexDirection: 'row',
            }}
          >
            <Text style={{flex: 1, fontSize: 18}}>
              {t('ANALYTIC')} 17
            </Text>
            <Text style={{flex: 3, fontSize: 18}}>
              {t('CONSTRUCTION_SITES_CHECK')}
            </Text>
          </Pressable>
        )}

        {user.analytics.stat22_23 && (
          <Pressable
            onPress={_analytic22_23}
            style={{
              paddingVertical: 15,
              paddingHorizontal: 5,
              borderBottomColor: theme.border,
              borderBottomWidth: 1,
              flexDirection: 'row',
            }}
          >
            <Text style={{flex: 1, fontSize: 18}}>
              {t('ANALYTIC')} 22/23
            </Text>
            <Text style={{flex: 3, fontSize: 18}}>
              {t('TECNICIANS_AVERAGES')}
            </Text>
          </Pressable>
        )}
      </ScrollView>
    </View>
  )
}

export default AnalyticsScreen