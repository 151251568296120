import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { Pressable, StyleSheet, View, Text, ScrollView, ActivityIndicator, Switch, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import * as PlanningAPI from "../../api/PlannningAPI";
import Table from "../../components/Table";
import getTheme from "../../constants/Colors";
import { Absence, ConstructionSite, Contract, Planning, Resource } from "../../models";
import { Reducer } from "../../store/reducers";
import WeekSelector from "./WeekSelector";
import { t } from "i18n-js";
import { SearchScreens } from "../search/SearchScreens";
import { alert } from "../../hooks/Alert";
import { logout } from "../../store/action/authAction";
import Status from "../../components/Status";
import Button from "../../components/Button";
import WeekReportView from "./WeekReportView";
import { sentryCapture } from "../../utils/sentryCapture";
import Modal from "react-native-modal";
import { DocStatus } from "../../constants/Status";
import * as FileSystem from "expo-file-system";
import ChooseConstructionSiteModal from "../../components/ChooseConstructionSiteModal/ChooseConstructionSiteModal";
import AbsenceItem from "../../components/AbsenceItem";
import AbsenceModal from "../../components/AbsenceModal";
import ChooseDateModal from "../../components/ChooseDateModal";
import { getDateOfIsoWeek, getWeekNumber } from "../../shared-utils/date-utils";
import ResourcesSettingsModal from "./ResourcesSettingsModal";

const filterOpenContracts = (c: Contract) => {
  switch (Number(c.Status)) {
    case DocStatus.OPEN.code:
    case DocStatus.IN_EXECUTION.code:
    case DocStatus.TO_BE_APPROVED.code:
    case DocStatus.IN_EXECUTION.code:
      return true
    default:
      return false
  }
}

type PlanningWithAbsence = {
  Days: {
    Date: Date
    ConstructionSites: ConstructionSite[]
    Absences: Absence[]
  }[]
} & Planning

export default function ResourcesScreen() {
  const theme = getTheme();
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const url = useSelector((state: Reducer) => state.auth.workSpace?.url || '')
  const token = useSelector((state: Reducer) => state.auth.token)

  const showPlanningWeekDays = useSelector(
    (state: Reducer) => state.settings.showPlanningWeekDays
  )

  const [loading, setLoading] = useState<boolean>(false);
  const [showWeekReport, setShowWeekReport] = useState<boolean>(false);

  const [modal, showModal] = useState(false);
  const [chooseConstructionSiteModalVisible, setChooseConstructionSiteModalVisible] = useState(false)
  const [year, setYear] = useState(new Date().getFullYear())
  const [selectedWeek, setSelectedWeek] = useState(getWeekNumber(new Date())[1])
  const [planning, setPlanning] = useState<PlanningWithAbsence | PlanningWithAbsence[]>();
  const [selectedConstructionSite, setSelectedCostructionSite] = useState<ConstructionSite>();
  const [selectedResource, setSelectedResource] = useState<{
    resource: Resource;
    date: Date;
  }>();

  const [weekReportExcelLoading, setWeekReportExcelLoading] = useState<boolean>(false)

  const [absence, setAbsence] = useState<Absence | undefined>()
  const closeAbsenceModal = () => setAbsence(undefined)

  const [chooseDateModalVisible, setChooseDateModalVisible] = useState(false)
  const _openChooseDateModal = () => setChooseDateModalVisible(true)
  const _closeChooseDateModal = () => setChooseDateModalVisible(false)

  const [settingsModalVisible, setSettingsModalVisible] = useState(false)
  const _openSettingsModal = () => setSettingsModalVisible(true)
  const _closeSettingsModal = () => setSettingsModalVisible(false)

  const date = useMemo(
    () => getDateOfIsoWeek(year, selectedWeek),
    [year, selectedWeek],
  )

  const _setSelectedWeekByDate = (date?: Date) => {
    const [, weekOfYear] = getWeekNumber(date || new Date())
    setYear(date?.getFullYear() || new Date().getFullYear())
    setSelectedWeek(weekOfYear)
  }

  const createAbsence = () => {
    if (selectedResource) {
      setAbsence({
        Date: selectedResource.date,
        Resource: selectedResource.resource,
        ResourceID: selectedResource.resource.ID,
        Start: "08:00",
        End: "08:00",
        Time: 0,
        Notes: "",
        OfficeNotes: "",
      })
      setChooseConstructionSiteModalVisible(false)
    }
  }

  const {resourceAssign, resourceAssignView, reportApprove} = useSelector(
    (state: Reducer) => state.user.permissions
  );

  const resource = useSelector(
    (state: Reducer) => state.general.resource
  );

  const planningDate = useMemo(() => {
    if (!planning) return;
    let plan = planning;

    if (Array.isArray(planning)) {
      plan = planning[0];
    }

    let {StartDate, EndDate} = plan as Planning;

    return StartDate.toLocaleDateString() + " - " + EndDate.toLocaleDateString();
  }, [planning]);

  const tableSizes = useMemo(() => {
    let sizes = [2, 2, 2, 2, 2];
    for (let i = 0; i < (showPlanningWeekDays || 5) - 5; i++) {
      sizes.push(2)
    }

    if (Array.isArray(planning)) sizes.unshift(1);

    return sizes;
  }, [planning, showPlanningWeekDays])

  const tableHeader = useMemo(() => {
    if (!planning) return [];

    let header = [];
    let plan = planning;
    if (Array.isArray(plan)) {
      plan = plan[0];
      header.push(null);
    }

    let {StartDate, EndDate} = plan;

    let start = new Date(StartDate);
    let end = new Date(EndDate);

    for (let i = start; i <= end; i.setDate(i.getDate() + 1)) {
      header.push(
        <View style={{paddingLeft: 3.5}}>
          <Text style={{textTransform: "capitalize", fontSize: 18}}>
            {i.toLocaleDateString(undefined, {weekday: "long"})}
          </Text>
          <Text>{i.toLocaleDateString()}</Text>
        </View>
      );
    }

    return header;
  }, [planning]);

  const showConstructionSiteSideSelectorHandler = (date: Date, resource: Resource) => {
    setSelectedResource({resource, date});
    setChooseConstructionSiteModalVisible(true)
  };

  const handleAdd = (site: ConstructionSite) => {
    if (!selectedResource) return;

    PlanningAPI.assign(site.ID, selectedResource?.resource.ID, selectedResource?.date)
      .then(pull)
      .catch((e) => {
        if (e.response && e.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
          dispatch(logout());
        } else {
          sentryCapture(e);
          alert("ERROR", "ERROR_ASSIGING_CONSTRUCTION_SITE");
        }
      });
  };

  const handleRemove = () => {
    if (!selectedResource || !selectedConstructionSite) return;

    PlanningAPI.remove(
      selectedConstructionSite.ID,
      selectedResource?.resource.ID,
      selectedResource?.date
    ).then(pull).catch((e) => {
      if (e.response && e.response.code == 401) {
        alert("ERROR", "YOUR_SESSION_IS_NOT_VALID");
        dispatch(logout());
      } else {
        sentryCapture(e);
        alert("ERROR", "ERROR_UNASSIGING_CONSTRUCTION_SITE");
      }
    });

    showModal(false);
  };

  const handleOpen = () => {
    // @ts-ignore
    navigation.navigate(`Sidebar`, {
      screen: 'SearchScreenStack',
      params: {
        screen: SearchScreens.CONSTRUCTION_SITES_DETAILS,
        params: selectedConstructionSite,
      },
    });
    showModal(false);
  };

  const rows = useMemo(() => {
    if (!planning) return [];

    let plans = planning;

    let rows: JSX.Element[][] = [];

    let showResource = Array.isArray(plans);
    if (!Array.isArray(plans)) plans = [plans];

    plans.forEach((plan: PlanningWithAbsence) => {
      let row = [];
      if (showResource) {
        row.push(
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'flex-end',
              flex: 1,
              marginRight: 6,
              minHeight: 200,
            }}
          >
            <View
              style={{
                transform: [{rotate: "-90deg"}],
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <Text numberOfLines={1} style={{width: "100%", fontSize: 18}}>
                {plan.Resource.FirstName + " " + plan.Resource.LastName}
              </Text>
            </View>
          </View>
        );
      }

      plan.Days.forEach((day, dayIndex) => {

        row.push(
          <View
            style={{
              paddingHorizontal: 6,
              borderLeftWidth: 1,
              flex: 1,
            }}
          >
            {day.Absences.filter(a => !a.Type?.Negative || reportApprove > 0).map((absence, absenceIndex) => (
              <AbsenceItem
                absence={absence}
                pull={() => pull()}
                key={`absence-${absence.Code}-${absenceIndex}`}
                index={absenceIndex}
              />
            ))}
            {day.ConstructionSites.map((site, siteIndex) => {
              let isLast = day.ConstructionSites.length - 1 == siteIndex;

              return (
                <Pressable
                  key={site.ID + "" + siteIndex + "" + dayIndex}
                  onPress={() => {
                    showModal(true);
                    setSelectedCostructionSite(site);
                    setSelectedResource({
                      resource: plan.Resource,
                      date: day.Date,
                    });
                  }}
                >
                  <View
                    key={site.ID + "" + siteIndex + "" + dayIndex}
                    style={{
                      borderBottomWidth: resourceAssign == 0 && isLast ? 0 : 1,
                      marginBottom: 5,
                      paddingBottom: 3,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: 6,
                      }}
                      key={"hstack" + site.ID + "" + siteIndex + "" + dayIndex}
                    >
                      <Text key={"text" + site.ID + "" + siteIndex + "" + dayIndex} style={{flex: 1, fontWeight: 'bold'}}>
                        {site.Code}
                      </Text>
                    </View>
                    <Text key={"siteName" + site.ID + "" + siteIndex + "" + dayIndex}>
                      {site.Name}
                    </Text>
                    <Text style={{fontSize: 12}}>{site.Address}</Text>
                  </View>
                </Pressable>
              );
            })}
            {(resourceAssign > 1 || (resourceAssign === 1 && plan.Resource.ID === resource?.ID)) && (
              <View key={"icon" + dayIndex} style={{alignItems: 'center'}}>
                <MaterialCommunityIcons
                  name="plus"
                  onPress={() => {
                    showConstructionSiteSideSelectorHandler(day.Date, plan.Resource);
                  }}
                  size={24}
                />
              </View>
            )}
          </View>
        );
      });

      rows.push(row);
    });

    return rows;
  }, [planning]);

  const pull = () => {
    setLoading(true);
    const [, thisWeek] = getWeekNumber(new Date())
    const thisYear = new Date().getFullYear()
    const week = (year - thisYear) * 52 + selectedWeek - thisWeek
    if (resourceAssignView > 0) {
      // Get all assignments
      PlanningAPI.getAssignments(week)
        .then(array => array.sort((a, b) => {
          return (b.Resource.PlanningWeight || 0) - (a.Resource.PlanningWeight || 0)
        }))
        .then(array => array.sort((a, b) => a.Resource.ID == resource?.ID ? -1 : 0))
        .then(p => {
          PlanningAPI.getWeekReport(week).then(r => {
            setPlanning(p.map((plan) => ({
              ...plan,
              Days: plan.Days.map((day) => ({
                ...day,
                Absences: r
                    .assignments
                    .find(a => a.Resource.ID == plan.Resource.ID)
                    ?.Days
                    .find(d => new Date(d.Date).toLocaleDateString() == new Date(day.Date).toLocaleDateString())
                    ?.Absences || [],
              })),
            })))
          }).catch((e) => {
            if (e.response && e.response.code == 401) {
              alert("ERROR", "YOUR_SESSION_IS_NOT_VALID")
              dispatch(logout())
            } else {
              sentryCapture(e)
              alert("ERROR", "ERROR_LOADING_WEEK_REPORT")
            }
          }).finally(() => setLoading(false))
        })
        .catch((e) => {
          setLoading(false)
          if (e.response && e.response.code == 401) {
            alert("ERROR", "YOUR_SESSION_IS_NOT_VALID")
            dispatch(logout())
          } else {
            sentryCapture(e)
            alert("ERROR", "ERROR_LOADING_PLANNING")
          }
        })
    } else {
      // Get only self resources
      PlanningAPI.getMyAssignments(week).then(s => {
        PlanningAPI.getWeekReport(week).then(r => {
          setPlanning([{
            ...s,
            Days: s.Days.map((day) => ({
              ...day,
              Absences: r
                  .assignments
                  .find(a => a.Resource.ID == s.Resource.ID)
                  ?.Days
                  .find(d => d.Date == day.Date)
                  ?.Absences || [],
            })),
          }])
        }).catch((e) => {
          if (e.response && e.response.code == 401) {
            alert("ERROR", "YOUR_SESSION_IS_NOT_VALID")
            dispatch(logout())
          } else {
            sentryCapture(e)
            alert("ERROR", "ERROR_LOADING_WEEK_REPORT")
          }
        }).finally(() => setLoading(false))
      }).catch((e) => {
        setLoading(false)
        if (e.response && e.response.code == 401) {
          alert("ERROR", "YOUR_SESSION_IS_NOT_VALID")
          dispatch(logout())
        } else {
          sentryCapture(e)
          alert("ERROR", "ERROR_LOADING_PLANNING")
        }
      })
    }
  };

  useEffect(() => {
    pull();
  }, [selectedWeek]);

  const _weekReportExcel = () => {
    setWeekReportExcelLoading(true)

    const [, thisWeek] = getWeekNumber(new Date())
    const thisYear = new Date().getFullYear()
    const week = (year - thisYear) * 52 + selectedWeek - thisWeek
    let file = `${url}getWeekReport/excel?weekSelector=${week}`
    if (Platform.OS == "web") {
      let anchor = document.createElement("a")
      document.body.appendChild(anchor)

      let headers = new Headers()
      headers.append('Authorization', token)

      fetch(file, { headers })
        .then(response => response.blob())
        .then(blob => {
          let url = window.URL.createObjectURL(blob)
          anchor.href = url
          anchor.download = "weekReport.xlsx"
          anchor.click()
          window.URL.revokeObjectURL(url)
        })
        .catch(e => {
          sentryCapture(e)
          alert("ERROR", "CANT_DOWNLOAD_WEEK_REPORT")
        })
        .finally(() => setWeekReportExcelLoading(false))
    } else {
      FileSystem.downloadAsync(
        file,
        FileSystem.documentDirectory + "weekReport.xlsx",
        { headers: { 'Authorization': token } }
      ).catch(e => {
        sentryCapture(e)
        alert("ERROR", "CANT_DOWNLOAD_WEEK_REPORT")
      }).finally(() => setWeekReportExcelLoading(false))
    }
  }

  const _previousWeek = () => {
    if (selectedWeek == 1) {
      setYear(year - 1)
      setSelectedWeek(52)
    } else {
      setSelectedWeek(selectedWeek - 1)
    }
  }

  const _reset = () => {
    setSelectedWeek(getWeekNumber(new Date())[1])
    setYear(new Date().getFullYear())
  }

  const _nextWeek = () => {
    if (selectedWeek >= 52) {
      setYear(year + 1)
      setSelectedWeek(1)
    } else {
      setSelectedWeek(selectedWeek + 1)
    }
  }

  return (
    <View style={[styles.container, {backgroundColor: theme.background, flex: 1}]}>
      {(selectedResource?.resource && chooseConstructionSiteModalVisible) && (
        <ChooseConstructionSiteModal
          visible={chooseConstructionSiteModalVisible}
          close={() => setChooseConstructionSiteModalVisible(false)}
          resourceID={selectedResource.resource.ID}
          onSelect={handleAdd}
          createAbsence={createAbsence}
        />
      )}
      {absence && (
        <AbsenceModal
          absence={absence}
          setAbsence={() => pull()}
          visible={true}
          onClose={closeAbsenceModal}
        />
      )}
      {chooseDateModalVisible && (
        <ChooseDateModal
          onClose={_closeChooseDateModal}
          date={date}
          setDate={_setSelectedWeekByDate}
        />
      )}
      <Modal
        style={{
          backgroundColor: 'white',
          padding: 15,
          borderRadius: 15,
          width: 400,
          alignSelf: 'center',
        }}
        isVisible={modal}
        onBackButtonPress={() => showModal(false)}
      >
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <Text style={{fontSize: 34}}>{t("MODIFY")}</Text>
          <MaterialCommunityIcons
            onPress={() => showModal(false)}
            size={24}
            name="close"
          />
        </View>

        {
          selectedConstructionSite?.Contracts.filter(filterOpenContracts).map((contract, index) => (
            <Pressable
              key={`${contract.ID}-${index}`}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 10,
                borderTopColor: theme.border,
                borderTopWidth: 1,
              }}
              onPress={() => {
                // @ts-ignore
                navigation.navigate('Sidebar', {
                  screen: 'SearchScreenStack',
                  params: {
                    screen: SearchScreens.REPORT_CREATE,
                    params: {
                      contractId: contract.ID,
                    },
                  },
                })
                showModal(false)
              }}
            >
              <Text>{contract.Name}</Text>
              <Status status={contract.Status} size="l" />
            </Pressable>
          ))
        }

        <Button
          onPress={handleOpen}
          style={{
            backgroundColor: theme.blue,
          }}
          titleT="OPEN_CONSTRUCTION_SITE"
        />

        {resourceAssign > 0 && (
          <Button
            onPress={handleRemove}
            style={{
              backgroundColor: theme.red,
            }}
            titleT="REMOVE"
          />
        )}
        <View style={{flex: 1}}/>
      </Modal>
      {settingsModalVisible && (
        <ResourcesSettingsModal
          onClose={_closeSettingsModal}
        />
      )}
      {loading && (
        <View style={{
          zIndex: 100,
          height: "100%",
          width: "100%",
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,.7)"
        }}>
          <ActivityIndicator color={theme.mainColor}/>
        </View>
      )}

      <View style={{flexDirection: 'row', alignItems: "center"}}>
        <View style={{flex: 1}}>
          <Text style={{fontSize: 18, marginRight: 6}}>
            {t("PLANNING")}
          </Text>
          <Text style={{flex: 1}}>{planningDate}</Text>
        </View>

        <View style={{flexDirection: 'row', alignItems: 'center', marginRight: 10}}>
          {reportApprove > 0 && showWeekReport && (
            <Button
              onPress={_weekReportExcel}
              style={{
                backgroundColor: theme.blue,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              disabled={weekReportExcelLoading}
            >
              {weekReportExcelLoading ? <ActivityIndicator /> : <MaterialCommunityIcons name="download" color="white" size={14} /> }
            </Button>
          )}
          <Text style={{marginRight: 5}}>{t('SHOW_WEEK_REPORT')}</Text>
          <Switch value={showWeekReport} onValueChange={setShowWeekReport} />
        </View>

        <MaterialCommunityIcons
          name='calendar'
          size={24}
          style={{marginLeft: 10, marginRight: 20}}
          onPress={_openChooseDateModal}
        />
        
        <View
          style={{
            flexDirection: 'row',
            marginLeft: 'auto',
            alignItems: 'center',
            backgroundColor: theme.thirdBackground,
            borderRadius: 15,
          }}
        >
          <WeekSelector
            onBefore={_previousWeek}
            onReset={_reset}
            onAfter={_nextWeek}
          />
        </View>

        <MaterialCommunityIcons
          name='cog'
          size={24}
          style={{marginLeft: 20}}
          onPress={_openSettingsModal}
        />
      </View>
      <ScrollView>
        {
          showWeekReport ? (
            <WeekReportView
              year={year}
              weekSelector={selectedWeek}
              mutate={pull}
            />
          ) : (
            <Table separator={() => <View style={{borderBottomWidth: 1, width: "96%", margin: "auto"}}/>}
              rowStyle={{marginVertical: 10}}
              style={{
                marginTop: 25,
              }}
              listStyle={{
                marginTop: 10,
                backgroundColor: theme.thirdBackground,
                borderRadius: 8,
              }}
              sizes={tableSizes}
              head={tableHeader}
              rows={rows}
            />
          )
        }
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 30,
    flex: 1,
  },
});
