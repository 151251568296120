import React, { useMemo, useState } from 'react'
import { Doc } from '../../../../models'
import { Pressable, View } from 'react-native'
import DocumentPreviewClient from '../DocumentView/DocumentPreviewClient'
import DocumentPreviewContact from '../DocumentView/DocumentPreviewContact'
import { MaterialCommunityIcons } from '@expo/vector-icons'

interface OrderPreviewClientsProps {
  doc: Doc
}

const OrderPreviewClients = ({doc}: OrderPreviewClientsProps) => {

  const [show, setShow] = useState<boolean>(true)
  const _toggle = () => setShow(!show)

  return (
    <View style={{flex: 1}}>
      <Pressable
        onPress={_toggle}
        style={{flexDirection: 'row', alignItems: 'center'}}
      >
        <MaterialCommunityIcons
          name={show ? 'chevron-up' : 'chevron-down'}
          size={24}
          color='black'
          style={{marginTop: 4}}
        />
        <DocumentPreviewClient
          textT='CLIENT'
          client={doc.Client}
          contact={doc.ClientContact}
        />
      </Pressable>

      {(doc.By && show) && (
        <View style={{flexDirection: 'row'}}>
          <DocumentPreviewClient
            textT='BY'
            client={doc.By}
            contact={doc.ByContact}
          />
        </View>
      )}

      {(doc.InvoiceTo && show) && (
        <View style={{flexDirection: 'row'}}>
          <DocumentPreviewClient
            textT='INVOICE_TO'
            client={doc.InvoiceTo}
            contact={doc.InvoiceToContact}
          />
        </View>
      )}
      
      {(doc.OfferNamedTo && show) && (
        <View style={{flexDirection: 'row'}}>
          <DocumentPreviewClient
            textT='OFFER_NAMED_TO'
            client={doc.OfferNamedTo}
            contact={doc.OfferNamedToContact}
          />
        </View>
      )}

      {show && doc.DocumentContacts.map(d => (
        <View
          key={d.ID}
          style={{flexDirection: 'row'}}
        >
          <DocumentPreviewContact
            documentContact={d}
            key={d.ID}
          />
        </View>
      ))}
    </View>
  )
}

export default OrderPreviewClients
