import { View, Text, Pressable, FlatList, ActivityIndicator, TextInput } from 'react-native'
import getTheme from "../../constants/Colors";
import { t } from "i18n-js";
import { Reducer } from "../../store/reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  search as searchAction,
} from "../../store/action/settingsAction";
import styles from "./style";
import React, { useEffect, useMemo, useState } from "react";
import { SearchScreens } from "./SearchScreens";
import { useNavigation } from "@react-navigation/native";
import SegmentedView from "../../components/SegmentedView";
import Button from "../../components/Button";
import { getConstructionSites } from "../../store/action/constructionSitesAction";
import { getAllClients } from "../../store/action/clientsActions";
import { DocStatus } from "../../constants/Status";
import { Client, ConstructionSite, Contract } from "../../models";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import Status from "../../components/Status";
import SearchOrderByDropdown, { SearchOrderBy } from "../../components/SearchOrderByDropdown/SearchOrderByDropdown";
import OfflineMeasures from '../../components/OfflineMeasures'
import DocsOffline from '../../components/DocsOffline';
import { userCanCreateBusinessPartners, userCanReadBusinessPartners } from '../../models/permissions';

const constructionSiteFilter = (c: ConstructionSite, searchFilter: string, sales: boolean): boolean => {
  if (c.Status == DocStatus.OFFER.code || c.Status == DocStatus.LOST.code) {
    return false;
  }
  if (!sales && c.Status == DocStatus.DONE.code) {
    return false;
  }

  return c.Name.toLowerCase().includes(searchFilter) ||
    String(c.Code).toLowerCase().includes(searchFilter) ||
    String(c.IDString).toLowerCase().includes(searchFilter);
}

const offerFilter = (c: ConstructionSite, searchFilter: string): boolean => {
  if (c.Status != DocStatus.OFFER.code && c.Status != DocStatus.LOST.code) {
    return false;
  }

  return c.Name.toLowerCase().includes(searchFilter) ||
    String(c.Code).toLowerCase().includes(searchFilter) ||
    String(c.IDString).toLowerCase().includes(searchFilter);
}

const clientFilter = (c: Client, searchFilter: string): boolean => {
  return c.Name.toLowerCase().includes(searchFilter);
}

const contractFilter = (c: Contract, sales: boolean): boolean => {
  if (sales) {
    return true
  }

  return c.Status == DocStatus.IN_EXECUTION.code
    || c.Status == DocStatus.OPEN.code
    || c.Status == DocStatus.TO_BE_APPROVED.code
    || c.Status == DocStatus.WIN.code
}

const Spearator = () => {
  const theme = getTheme();

  return (
    <View
      style={{
        borderBottomColor: theme.border,
        borderBottomWidth: 1,
        marginHorizontal: 10,
      }}
    />
  )
}

const SearchScreen = () => {
  const theme = getTheme();
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const serverReachable = useSelector((state: Reducer) => state.sync.serverReachable)
  const user = useSelector((state: Reducer) => state.user);
  const searchString = useSelector((state: Reducer) => state.settings.searchString)
  const constructionSites = useSelector((state: Reducer) => state.constructionSite.constructionSites);
  const constructionSitesLoading = useSelector(
    (state: Reducer) => state.sync.constructionSitesLoading
  )
  const clients = useSelector((state: Reducer) => state.client.clients);
  const clientsLoading = useSelector((state: Reducer) => state.sync.clientsLoading)

  const [search, setSearch] = useState<string>(searchString)
  const [orderby, setOrderBy] = useState<SearchOrderBy>(SearchOrderBy.CODE)
  const [orderReverse, setOrderReverse] = useState<boolean>(false)

  const _search = (search: string) => {
    setSearch(search)
    dispatch(searchAction(search))
  }
  const _clearSearch = () => _search('')

  const _onSubmitEditing = (e:any) => {
    const search = e.target.value
    if (search.length > 0) {
      const filtered = constructionSites
          .filter(c => c.Code == search || c.IDString == search)
      if (filtered.length == 1) {
        // @ts-ignore
        navigation.navigate("ConstructionSitesDetails", filtered[0])
      }
    }
  }

  const constructionSitesFitlered = useMemo(
    () => constructionSites
      .filter(c => constructionSiteFilter(c, searchString.toLowerCase(), user.permissions.sales))
      .sort((a, b) => {
        switch (Number(orderby)) {
          case SearchOrderBy.CODE:
            return (orderReverse ? -1 : 1) * a.Code.localeCompare(b.Code)
          case SearchOrderBy.NAME:
            return (orderReverse ? -1 : 1) * a.Name.localeCompare(b.Name)
          default:
            return 0
        }
      }),
    [searchString, constructionSites, orderby, orderReverse]
  );

  const offersFiltered = useMemo(
    () => constructionSites
      .filter(c => offerFilter(c, searchString.toLowerCase()))
      .sort((a, b) => {
        switch (Number(orderby)) {
          case SearchOrderBy.CODE:
            return (orderReverse ? -1 : 1) * a.Code.localeCompare(b.Code)
          case SearchOrderBy.NAME:
            return (orderReverse ? -1 : 1) * a.Name.localeCompare(b.Name)
          default:
            return 0
        }
      }),
    [searchString, constructionSites, orderby, orderReverse]
  );

  const clientsFiltered = useMemo(
    () => clients
      .filter(c => clientFilter(c, searchString.toLowerCase()))
      .sort((a, b) => {
        switch (Number(orderby)) {
          case SearchOrderBy.CODE:
            return (orderReverse ? -1 : 1) * String(a.ID || '').localeCompare(String(b.ID || ''))
          case SearchOrderBy.NAME:
            return (orderReverse ? -1 : 1) * a.Name.localeCompare(b.Name)
          default:
            return 0
        }
      }),
    [searchString, constructionSites, orderby, orderReverse]
  );

  useEffect(() => {
    if (!constructionSitesLoading) {
      dispatch(getConstructionSites())
    }
    if (!clientsLoading) {
      user.permissions.sales && dispatch(getAllClients());
    }
  }, [searchString]);

  const _newDocument = () => {
    // @ts-ignore
    navigation.navigate(`SearchScreenStack`, {
      screen: SearchScreens.CREATE_OFFER,
    });
  }

  const _newClient = () => {
    // @ts-ignore
    navigation.navigate(SearchScreens.CREATE_CLIENT, {
      setClient: () => user.permissions.sales && dispatch(getAllClients()),
    });
  }

  const _offerToCheck = () => {
    // @ts-ignore
    navigation.navigate(SearchScreens.OFFER_TO_CHECK)
  }

  const _reverseOrder = () => setOrderReverse(!orderReverse)

  const _renderClient = ({ item, index }: { item: Client, index: number }) => {
    const _press = () => {
      if (!userCanReadBusinessPartners(user)) {
        return
      }
      // @ts-ignore
      navigation.navigate(SearchScreens.CLIENT_OVERVIEW, {
        clientId: String(item.ID),
      });
    }
    return (
      <Pressable
        onPress={_press}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: 10,
          paddingVertical: 15,
        }}
        key={`client${item.ID}-${index}`}
      >
        <Text style={{ flex: 1 }}>{item.ID}</Text>
        <Text style={{ flex: 3 }}>{item.Name}</Text>
        <Text style={{ flex: 2 }}>{item.Email}</Text>
        <Text style={{ flex: 2 }}>{item.Phone}</Text>
        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 0.5, justifyContent: 'center' }}>
          {
            item.Contacts.length > 0 ? (
              <>
                <MaterialIcons
                  name="people"
                  size={14}
                />
                <Text>{item.Contacts.length}</Text>
              </>
            ) : <></>
          }
        </View>
      </Pressable>
    )
  }

  const _renderConstructionSite = ({ item, index }: { item: ConstructionSite, index: number }) => {
    const _press = () => {
      // @ts-ignore
      navigation.navigate("ConstructionSitesDetails", item);
    };
    return (
      <Pressable
        onPress={_press}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: 20,
          paddingVertical: 10,
        }}
        key={`client${item.ID}-${index}`}
      >
        <Text style={{ flex: 2 }}>{item.Code ? item.Code : item.IDString}</Text>
        <View style={{ flex: 10 }}>
          <Text>{item.Name}</Text>
          <Text style={{ fontSize: 9 }}>{item.Address}</Text>
        </View>
        <Status status={item.Status} size="m" />
        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'center' }}>
          {
            item.Contracts.length > 0 ? (
              <>
                <MaterialCommunityIcons
                  name="file-document"
                  size={14}
                />
                <Text>{item.Contracts.filter(c => contractFilter(c, user.permissions.sales)).length}</Text>
              </>
            ) : <></>
          }
        </View>
      </Pressable>
    )
  }

  const views = (index: number) => {
    if (index == 2) {
      return (
        <FlatList
          data={clientsFiltered}
          renderItem={_renderClient}
          style={{
            flex: 1,
            backgroundColor: 'white',
            borderRadius: 10,
          }}
          ItemSeparatorComponent={Spearator}
          ListEmptyComponent={() => clientsLoading ? <ActivityIndicator /> : <></>}
          key={`search-client`}
          // @ts-ignore
          listKey={`search-client`}
          keyExtractor={(item, index) => `search-client-${index}-${item.ID}`}
        />
      )
    }

    return (
      <FlatList
        data={index == 0 ? constructionSitesFitlered : offersFiltered}
        renderItem={_renderConstructionSite}
        style={{
          flex: 1,
          backgroundColor: 'white',
          borderRadius: 10,
        }}
        ItemSeparatorComponent={Spearator}
        ListEmptyComponent={() => constructionSitesLoading ? <ActivityIndicator /> : <></>}
        key={`search-construction-site`}
        // @ts-ignore
        listKey={`search-construction-site`}
        keyExtractor={(item, index) => `search-construction-site-${index}-${item.ID}`}
      />
    )
  }

  return (
    <View style={styles.container}>
      
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {serverReachable ? (<>
          <View style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            borderWidth: 1,
            borderColor: theme.border,
            borderRadius: 4,
          }}>
            <MaterialIcons
              name="search"
              style={{
                margin: 6,
                marginLeft: 9,
              }}
              size={18}
            />
            <TextInput
              placeholder={t("SEARCH")}
              style={{
                flex: 1,
                borderRadius: 4,
                paddingVertical: 9,
                paddingHorizontal: 3,
                fontSize: 14,
              }}
              value={search}
              returnKeyType={"search"}
              onChangeText={_search}
              onSubmitEditing={_onSubmitEditing}
            />
            <MaterialIcons
              name="close"
              style={{
                padding: 2,
                marginRight: 9,
              }}
              size={18}
              onPress={_clearSearch}
            />
          </View>

          <MaterialCommunityIcons
            onPress={_reverseOrder}
            name={orderReverse ? 'chevron-down' : 'chevron-up'}
            color='black'
            size={34}
            style={{ paddingLeft: 10 }}
          />

          <View style={{ width: 10 }} />
          <SearchOrderByDropdown
            searchOrderBy={orderby}
            setSearchOrderBy={setOrderBy}
          />
          <View style={{ width: 10 }} />
        </>) : (
          <View style={{flex: 1}} />
        )}

        {user.permissions.sales && (
          <>
            <Button
              style={{
                backgroundColor: theme.blue,
                marginRight: 0,
              }}
              _textStyle={{ fontSize: 20 }}
              onPress={_newDocument}
              title='+'
              rightComponent={<MaterialCommunityIcons name='file-document' size={24} color='white' />}
            />
            {(serverReachable && userCanCreateBusinessPartners(user)) && (
              <Button
                style={{
                  backgroundColor: theme.blue,
                  marginRight: 0,
                  marginLeft: 10,
                }}
                _textStyle={{ fontSize: 20 }}
                onPress={_newClient}
                title='+'
                rightComponent={<MaterialCommunityIcons name='account' size={24} color='white' />}
              />
            )}
            {serverReachable && (
              <Button
                style={{
                  backgroundColor: theme.blue,
                  marginRight: 0,
                  marginLeft: 10,
                }}
                _textStyle={{ fontSize: 20 }}
                onPress={_offerToCheck}
              >
                <MaterialCommunityIcons name='eye' size={24} color='white' />
              </Button>
            )}
          </>
        )}
      </View>

      {serverReachable ? (
        user.permissions.sales ? (
          <SegmentedView
            titlesT={['SEARCH_CATEGORY_SITES', 'SEARCH_CATEGORY_OFFERS', 'SEARCH_CATEGORY_CLIENTS']}
            views={views}
          />
        ) : (
          <FlatList
            data={constructionSitesFitlered}
            renderItem={_renderConstructionSite}
            style={{
              flex: 1,
              backgroundColor: 'white',
              borderRadius: 10,
            }}
            ItemSeparatorComponent={Spearator}
            ListEmptyComponent={() => constructionSitesLoading ? <ActivityIndicator /> : <></>}
            key={`search-offer`}
            // @ts-ignore
            listKey={`search-offer`}
            keyExtractor={(item, index) => `search-offer-${index}-${item.ID}`}
          />
        )
      ) : (
        <View style={{flex : 1}} />
      )}

      {user.permissions.sales && (
        <DocsOffline />
      )}

      {user.permissions.sales && (
        <OfflineMeasures />
      )}
    </View>
  );
};

export default SearchScreen;
