import {
  ANALYTIC17_CONFIG_ALL_OPEN,
  ANALYTIC17_CONFIG_PRICE_LIST,
  ANALYTIC17_CONFIG_SHOW_CONTRACT,
  ANALYTIC17_CONFIG_USE_BOM_ENGINEERING,
  ANALYTIC17_CONFIG_USE_BOM_EXTERNAL_COSTS,
  ANALYTIC17_CONFIG_USE_BOM_SPECIAL_MATERIAL,
  ANALYTIC17_CONFIG_USE_HOURS_COST,
  ANALYTIC22_23_CONFIG_PREVIOUS_YEARS,
  ANALYTIC22_23_CONFIG_PRICE_LIST,
  ANALYTIC22_23_CONFIG_WAREHOUSE_CONSTRUCTION_SITE_CODE,
  ANALYTIC22_23_CONFIG_YEAR,
  LOGOUT,
  MEASURE_SHOW_APPROVED_QUANTITY_PREVIOUS,
  MEASURE_SHOW_BILLABLE_QUANTITY_PREVIOUS,
  MEASURE_SHOW_BILLED_QUANTITY,
  MEASURE_SHOW_DONE_QUANTITY_PREVIOUS,
  MEASURE_SHOW_SOLD,
  SET_SEARCH_STRING,
  SET_SHOW_PRICE,
  SHOW_DOCS_OFFLINE,
  SHOW_OFFLINE_MEASURES,
  SHOW_PLANNING_WEEK_DAYS,
} from '../types'

export type settings = {
  showPriceLevel: number;
  measureShowSold: boolean
  measureShowDoneQuantityPrevious: boolean
  measureShowApprovedQuantityPrevious: boolean
  measureShowBillableQuantityPrevious: boolean
  measureShowBilledQuantity: boolean
  showOfflineMeasures: boolean
  showDocsOffline: boolean
  searchString: string
  showPlanningWeekDays: 5 | 6 | 7
  analytic17: {
    allOpen: boolean
    priceList: number
    useBoMEngineering: boolean
    useBoMSpecialMaterial: boolean
    useBoMExternalCosts: boolean
    useHoursCost: number
    showContract: boolean
  },
  analytic22_23: {
    year: number
    previousYears: number
    priceList: number
    warehouseConstructionSiteCode: string
  },
}

const initialState: settings = {
  showPriceLevel: 0,
  measureShowSold: true,
  measureShowDoneQuantityPrevious: true,
  measureShowApprovedQuantityPrevious: true,
  measureShowBillableQuantityPrevious: true,
  measureShowBilledQuantity: true,
  showOfflineMeasures: true,
  showDocsOffline: true,
  searchString: '',
  showPlanningWeekDays: 5,
  analytic17: {
    allOpen: true,
    priceList: 4,
    useBoMEngineering: true,
    useBoMSpecialMaterial: false,
    useBoMExternalCosts: false,
    useHoursCost: -2,
    showContract: true,
  },
  analytic22_23: {
    year: new Date().getFullYear(),
    previousYears: 0,
    priceList: 4,
    warehouseConstructionSiteCode: '',
  },
}

export default function (
  state = initialState,
  action: { type: string; payload: any },
): settings {
  switch (action.type) {
    case SET_SHOW_PRICE:
      return {
        ...state,
        showPriceLevel: action.payload,
      }
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }
    case MEASURE_SHOW_SOLD:
      return {
        ...state,
        measureShowSold: !state.measureShowSold,
      }
    case MEASURE_SHOW_DONE_QUANTITY_PREVIOUS:
      return {
        ...state,
        measureShowDoneQuantityPrevious: !state.measureShowDoneQuantityPrevious,
      }
    case MEASURE_SHOW_APPROVED_QUANTITY_PREVIOUS:
      return {
        ...state,
        measureShowApprovedQuantityPrevious: !state.measureShowApprovedQuantityPrevious,
      }
    case MEASURE_SHOW_BILLABLE_QUANTITY_PREVIOUS:
      return {
        ...state,
        measureShowBillableQuantityPrevious: !state.measureShowBillableQuantityPrevious,
      }
    case MEASURE_SHOW_BILLED_QUANTITY:
      return {
        ...state,
        measureShowBilledQuantity: !state.measureShowBilledQuantity,
      }
    case SHOW_OFFLINE_MEASURES:
      return {
        ...state,
        showOfflineMeasures: !state.showOfflineMeasures,
      }
    case SHOW_DOCS_OFFLINE:
      return {
        ...state,
        showDocsOffline: !state.showDocsOffline,
      }
    case SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload.searchString,
      }
    case SHOW_PLANNING_WEEK_DAYS:
      return {
        ...state,
        showPlanningWeekDays: action.payload,
      }
    case ANALYTIC17_CONFIG_ALL_OPEN:
      return {
        ...state,
        analytic17: {
          ...state.analytic17,
          allOpen: action.payload,
        },
      }
    case ANALYTIC17_CONFIG_PRICE_LIST:
      return {
        ...state,
        analytic17: {
          ...state.analytic17,
          priceList: action.payload,
        },
      }
    case ANALYTIC17_CONFIG_SHOW_CONTRACT:
      return {
        ...state,
        analytic17: {
          ...state.analytic17,
          showContract: action.payload,
        },
      }
    case ANALYTIC17_CONFIG_USE_BOM_ENGINEERING:
      return {
        ...state,
        analytic17: {
          ...state.analytic17,
          useBoMEngineering: action.payload,
        },
      }
    case ANALYTIC17_CONFIG_USE_BOM_EXTERNAL_COSTS:
      return {
        ...state,
        analytic17: {
          ...state.analytic17,
          useBoMExternalCosts: action.payload,
        },
      }
    case ANALYTIC17_CONFIG_USE_BOM_SPECIAL_MATERIAL:
      return {
        ...state,
        analytic17: {
          ...state.analytic17,
          useBoMSpecialMaterial: action.payload,
        },
      }
    case ANALYTIC17_CONFIG_USE_HOURS_COST:
      return {
        ...state,
        analytic17: {
          ...state.analytic17,
          useHoursCost: action.payload,
        },
      }
    case ANALYTIC22_23_CONFIG_YEAR:
      return {
        ...state,
        analytic22_23: {
          ...state.analytic22_23,
          year: action.payload,
        },
      }
    case ANALYTIC22_23_CONFIG_PREVIOUS_YEARS:
      return {
        ...state,
        analytic22_23: {
          ...state.analytic22_23,
          previousYears: action.payload,
        },
      }
    case ANALYTIC22_23_CONFIG_PRICE_LIST:
      return {
        ...state,
        analytic22_23: {
          ...state.analytic22_23,
          priceList: action.payload,
        },
      }
    case ANALYTIC22_23_CONFIG_WAREHOUSE_CONSTRUCTION_SITE_CODE:
      return {
        ...state,
        analytic22_23: {
          ...state.analytic22_23,
          warehouseConstructionSiteCode: action.payload,
        },
      }
    default:
      return state;
  }
}

const PriceLevelNoPrice = 0;
const PriceLevelClient = 1;
const PriceLevelInternal = 2;
export const PriceLevel = {
  NO_PRICE: PriceLevelNoPrice,
  CLIENT: PriceLevelClient,
  INTERNAL: PriceLevelInternal,
};