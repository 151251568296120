import { Bonus } from '../models'
import { t } from 'i18n-js'

export const deleteBonus = async (
  baseUrl: string,
  token: string,
  workspace: string,
  bonus: Bonus,
): Promise<void> => {
  let url = baseUrl
  url += `bonus/deleteBonus`
  url += `?workspace=${workspace}`
  url += `&token=${encodeURIComponent(token)}`
  url += `&year=${bonus.year}`
  url += `&resourceId=${bonus.resourceId}`

  const response = await fetch(url, {
    method: 'DELETE',
  })
  if (!response.ok) {
    throw new Error(t('ERROR_DELETING_BONUS'))
  }
  const data = await response.json()
  if (!data.ok) {
    throw new Error(t('ERROR_DELETING_BONUS'))
  }
}

export const getBonusesByYear = async (
  baseUrl: string,
  token: string,
  workspace: string,
  year: number,
): Promise<Bonus[]> => {
  let url = baseUrl
  url += `bonus/getBonusesByYear`
  url += `?workspace=${workspace}`
  url += `&token=${encodeURIComponent(token)}`
  url += `&year=${year}`

  const response = await fetch(url)

  if (!response.ok) {
    throw new Error(t('ERROR_FETCHING_BONUSES'))
  }

  const data = await response.json()
  if (!data.ok) {
    throw new Error(t('ERROR_FETCHING_BONUSES'))
  }

  return data.bonuses
}

export const insertOrUpdateBonuses = async (
  baseUrl: string,
  token: string,
  workspace: string,
  bonuses: Bonus[],
): Promise<void> => {
  let url = baseUrl
  url += `bonus/insertOrUpdateBonuses`
  url += `?workspace=${workspace}`
  url += `&token=${encodeURIComponent(token)}`

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bonuses)
  })
  console.log(response.ok)
  console.log(response)
  if (!response.ok) {
    throw new Error(t('ERROR_INSERTING_OR_CREATING_BONUSES'))
  }
  console.log(response)
  const data = await response.json()
  console.log(data)
  if (!data.ok) {
    throw new Error(t('ERROR_INSERTING_OR_CREATING_BONUSES'))
  }
}
