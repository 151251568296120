import React, { useMemo, useState } from 'react';
import { Contract } from "../../../models";
import { statusCodeToText } from "../../../constants/Status";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import { Reducer } from "../../../store/reducers";
import { t } from 'i18n-js';
import EditContractModal from "../../../components/EditContractModal";
import { PriceLevel } from "../../../store/reducers/settingsReducer";
import { ActivityIndicator, Text, View } from 'react-native';
import Formatter from '../../../utils/formatters';

interface Props {
  contracts: Contract[]
  contract?: Contract;
  expand: boolean;
  setExpand: (expand: boolean) => void;
  reload: () => void;
}

const ContractHeader = ({contracts, contract, expand, setExpand, reload}: Props) => {

  const currency = useSelector((state: Reducer) => state.user.currency.ID);
  const sales = useSelector((state: Reducer) => state.user.permissions.sales);
  const showPriceLevel = useSelector((state:Reducer) => state.settings.showPriceLevel);

  const [contractModalVisible, setContractModalVisible] = useState<boolean>(false);

  const warrantyForName = useMemo(
    () => contracts.find(c => c.ID == contract?.WarrantyFor)?.Name,
    [contract, contracts]
  )

  const _onPress = () => {
    setExpand(!expand);
  }

  const _edit = () => {
    setContractModalVisible(true);
  }

  const currencyFormatter = Formatter.currency(currency);

  if (!contract) {
    return (
      <View
        style={{
          flexDirection: "row",
          borderRightWidth: 10,
          padding: 8,
          backgroundColor: "white",
          margin: 5,
          borderRadius: 5,
          justifyContent: "space-between",
        }}
      >
        <ActivityIndicator />
      </View>
    );
  }

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: 'center',
        borderRightWidth: 5,
        padding: 10,
        backgroundColor: "white",
        margin: 5,
        borderRadius: 5,
        justifyContent: "space-between",
        borderRightColor: statusCodeToText(contract.Status).color,
      }}
    >
      {contractModalVisible && (
        <EditContractModal
          reload={reload}
          visible={contractModalVisible}
          setVisible={setContractModalVisible}
          contract={contract}
        />
      )}
      <MaterialCommunityIcons
        onPress={_onPress}
        size={20}
        color={"black"}
        name={expand ? "arrow-collapse" : "arrow-expand"}
        style={{marginRight: 6}}
      />
      <View style={{flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start'}}>
        <Text style={{fontSize: 16}}>
          {`  ` + t('CONTRACT_NAME')}: {contract.Name} {showPriceLevel >= PriceLevel.CLIENT ? `- ${currencyFormatter.format(contract.Amount)}`: "" } - {warrantyForName && `  -  ${t('WARRANTY_FOR')}: ${warrantyForName}`}
        </Text>
        {(contract?.Notes?.length > 0 && sales) && (
          <Text style={{marginTop: 10, fontWeight: 'bold'}}>
            {t("NOTES")}:
          </Text>
        )}
        {(contract?.Notes?.length > 0 && sales) && (
          <Text style={{marginTop: 5, marginBottom: 10}}>
            {contract.Notes}
          </Text>
        )}
      </View>
      

      {
        sales && (
          <MaterialCommunityIcons
            onPress={_edit}
            size={20}
            color={"black"}
            name={"pencil"}
          />
        )
      }
    </View>
  );
}

export default ContractHeader;