import React from 'react'
import { Resource } from '../../../models'
import Modal from 'react-native-modal'
import { FlatList, Pressable, Text, View } from 'react-native'
import { t } from 'i18n-js'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import getTheme from '../../../constants/Colors'

interface Props {
  resources: Resource[]
  onClose: () => void
  onSelect: (resource: Resource) => void
}

const ChooseResourceModal = ({ resources, onClose, onSelect }: Props) => {

  const theme = getTheme()

  const _render = ({item, index}: {item: Resource, index: number}) => {
    const _choose = () => {
      onSelect(item)
      onClose()
    }

    return (
      <Pressable
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          padding: 10,
          borderTopColor: theme.border,
          borderTopWidth: 1
        }}
        key={`resource-${item.ID}-${index}`}
        onPress={_choose}
      >
        <Text style={{fontSize: 16}}>
          {item.FirstName} {item.LastName}
        </Text>
      </Pressable>
    )
  }
  return (
    <Modal
      isVisible={true}
      onBackdropPress={onClose}
      style={{
        width: 400,
        height: 400,
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
        alignSelf: 'center',
      }}
    >
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Text style={{fontSize: 18}}>
          {t('CHOOSE_RESOURCE')}
        </Text>

        <MaterialCommunityIcons
          name='close'
          size={34}
          color='black'
          onPress={onClose}
        />
      </View>

      <FlatList
        data={resources}
        renderItem={_render}
      />
    </Modal>
  )
}

export default ChooseResourceModal
