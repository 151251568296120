import React, { useEffect, useState } from 'react'
import { Bonus } from '../../../models'
import { ActivityIndicator, Text, TextInput, View } from 'react-native'
import getTheme from '../../../constants/Colors'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { deleteBonus } from '../../../api/BonusAPI'
import { Reducer } from '../../../store/reducers'
import { useSelector } from 'react-redux'
import { alert } from '../../../hooks/Alert'
import { sentryCapture } from '../../../utils/sentryCapture'

interface ConfigureBonusProps {
  item: Bonus
  index: number
  setBonus: (bonus: Bonus) => void
  remove: () => void
}

const ConfigureBonus = ({
  item,
  index,
  setBonus,
  remove,
}: ConfigureBonusProps) => {

  const theme = getTheme()

  const token = useSelector((state: Reducer) => state.auth.token)
  const workSpace = useSelector((state: Reducer) => state.auth.workSpace)

  const [loading, setLoading] = useState<boolean>(false)

  const [salaryOk, setSalaryOk] = useState<boolean>(true)
  const [salary, setSalary] = useState<string>(
    item.salary.toString()
  )

  const [bonusJuneOk, setBonusJuneOk] = useState<boolean>(true)
  const [bonusJune, setBonusJune] = useState<string>(
    item.bonusJune.toString()
  )

  const [bonusDecemberOk, setBonusDecemberOk] = useState<boolean>(true)
  const [bonusDecember, setBonusDecember] = useState<string>(
    item.bonusDecember.toString()
  )

  const _salary = (s: string) => {
    setSalary(s)
    const salary = Number(s)
    setSalaryOk(!isNaN(salary))
    if (!isNaN(salary)) {
      setBonus({
        ...item,
        salary,
      })
    }
  }

  const _bonusJune = (s: string) => {
    setBonusJune(s)
    const bonusJune = Number(s)
    setBonusJuneOk(!isNaN(bonusJune))
    if (!isNaN(bonusJune)) {
      setBonus({
        ...item,
        bonusJune,
      })
    }
  }

  const _bonusDecember = (s: string) => {
    setBonusDecember(s)
    const bonusDecember = Number(s)
    setBonusDecemberOk(!isNaN(bonusDecember))
    if (!isNaN(bonusDecember)) {
      setBonus({
        ...item,
        bonusDecember,
      })
    }
  }

  const _remove = () => {
    setLoading(true)
    deleteBonus(
      workSpace?.secondaryUrl || '',
      token,
      workSpace?.name || '',
      item
    ).then(remove).catch(e => {
      alert('ERROR', 'ERROR_DELETING_BONUS')
      sentryCapture(e)
      console.error(e)
    }).finally(() => setLoading(false))
  }

  return (
    <View
      key={`bonus-${item.year}-${item.resourceId}-${index}`}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        borderTopWidth: index == 0 ? 0 : 1,
        borderTopColor: theme.border,
        paddingHorizontal: 10,
      }}
    >
      <Text style={{flex: 1}}>
        {item.resource.FirstName} {item.resource.LastName}
      </Text>

      <View style={{flex: 1, paddingHorizontal: 10}}>
        <TextInput
          style={{
            textAlign: 'right',
            backgroundColor: 'white',
            padding: 5,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: salaryOk ? theme.border : theme.red,
          }}
          value={salary}
          onChangeText={_salary}
        />
      </View>

      <View style={{flex: 1, paddingHorizontal: 10}}>
        <TextInput
          style={{
            textAlign: 'right',
            backgroundColor: 'white',
            padding: 5,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: bonusJuneOk ? theme.border : theme.red,
          }}
          value={bonusJune}
          onChangeText={_bonusJune}
        />
      </View>

      <View style={{flex: 1, paddingHorizontal: 10}}>
        <TextInput
          style={{
            textAlign: 'right',
            backgroundColor: 'white',
            padding: 5,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: bonusDecemberOk ? theme.border : theme.red,
          }}
          value={bonusDecember}
          onChangeText={_bonusDecember}
        />
      </View>

      {loading ? (
        <ActivityIndicator />
      ) : (
        <MaterialCommunityIcons
          name='close'
          size={24}
          color='black'
          onPress={_remove}
          style={{marginLeft: 10}}
        />
      )}
    </View>
  )
}

export default ConfigureBonus
